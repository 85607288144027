//BLNG Color Pallete
$corp-color:				      #00713b;		//green
$mod-bgcolor:				      #f2f2f2;	  //mid-green
$bgcolor:				          #f0f0f0;		//lightgray
$top-most-nav-color:			#888888;		//Gray
$nav-item-color:			    #222222;		//dark-gray
// Content
$content-font-color:	    $nav-item-color;
//API
$accordion-open-bgcolor:	#e6e6e6;
$search-color:			      #e2e2e2;		//mid-green
//Basic Color Pallete
$white:				            #ffffff;
$black:				            #000000;
$gray:                    #cccccc;
