//Top-most Navigation
.top-most-nav {
  font-size: 75%;
  margin: 0.5rem 0;

  .menu li {
    a {
      color: $top-most-nav-color;
      border-right: 1px solid $top-most-nav-color;
      padding: 0 1rem;
    }

    &:last-child a {border-right: none;}
  }

}
//Main Navigation
nav.top-bar {
  position: relative;
  .top-bar-left, .top-bar-right {margin: 1.3rem 0;}

  .top-bar-title {
    padding: 0.3rem 0.5rem;
    //padding:0.5rem 1rem 0.8rem 0;
    img {width:80px;}

  }
  input {max-width: 100%; margin-right: 0;}

  .menu {
    > li > a {
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
        //******State******
        &:hover, &:focus {color: $corp-color;}
    }
    //******Active******
  .active > a {color: $corp-color;}
  }

  .toggle-button {
      position: fixed;
      top: 15px;
      right: 5px;
      padding: 9px 10px;
      margin: 8px 15px 8px 0;
      height: 20px;
      width: 50px;
      cursor: pointer;
      z-index: 3;
  }

  .bar {
      background-color: #333;
      display: block;
      width: 100%;
      height: 4px;
      border: 0;
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
  }
  .bar + .bar {margin-top: 4px;}
  .middle {top: 11px;}
  .bottom {top: 22px;}


  .overlay {
      position: fixed;
      //background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: opacity .35s, visibility .35s, height .35s;
      z-index: 5;
  }
  .nav-active {
      opacity: 1;
      visibility: visible;
      height: 100%;
  /*    max-height: 340px;*/
  }
  .overlay ul {
      display: block;
      position: relative;
      top: 60px;
      left: 0;
      font-size: 50px;
      font-weight: 400;
      text-align: center;
      list-style: none;
      padding: 0;
  }
  .overlay ul li {
      display: block;
  }
  .overlay ul li a {
      color: #fff;
      text-decoration: none;
  }


}

//********Mobile Nvigation************
.cd-header {
	position: absolute;
	top: 0;
	left: 0;
	height: 81px;
	width: 100%;
	z-index: 6;
	box-shadow: 0 1px 2px rgba(#000, .3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Force Hardware Acceleration in WebKit */
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  &.is-fixed {
    position: fixed;
    top: -81px;
    background-color: rgba(2, 23, 37, 0.96);
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }

  &.is-visible {transform: translate3d(0, 100%, 0);}

  &.menu-is-open {background-color: rgba(2, 23, 37, 0.96);}
}

.mobile-nav-trigger {
  position: absolute;
  right: 15px;
  top: 0;
  height: 100%;
  width: 50px;
  cursor: pointer;

	.h-icon {
    /* this span is the central line of the menu icon */
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;

    transform: translateX(-50%) translateY(-50%);
    width: 25px;
    height: 4px;
    background-color: $corp-color;

    transition: background-color 0.3s;
    /* these are the upper and lower lines in the menu icon */
		&::before, &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $corp-color;
      right: 0;

			transition: transform .3s, top .3s, background-color 0s;
		}

		&::before {top:-8px;}

		&::after {top: 8px;}

		&.is-clicked {
			background-color: transparent;

			&::before, &::after {background-color: $corp-color;}

			&::before {
				top: 0;
				transform: rotate(135deg);
			}

			&::after {
				top: 0;
				transform: rotate(225deg);
			}
		}
	}

	/*@media only screen and (min-width: 768px) {
		width: 100px;
		padding-left: 1em;
		background-color: transparent;
		height: 30px;
		line-height: 30px;
		right: 2.2em;
		transform: translateY(-50%);

		.cd-menu-text {display: inline-block;}

		.cd-menu-icon {
			left: auto;
			right: 1em;
			transform: translateX(0) translateY(-50%);
		}
	}*/
}

.mobile-nav {
	/* by default it's hidden - on top of the viewport */
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
  margin: 0;
	background:white;
	z-index: 5;
	text-align: center;
	padding: 100px 0 80px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	overflow: auto;
	/* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
	-webkit-overflow-scrolling: touch;

	transform: translateY(-100%);
	transition-property: transform;
	transition-duration: 0.4s;

	li {
    list-style-type: none;
    font-size: 22px;
    font-size: 1.375rem;
		font-weight: 300;
		-webkit-font-smoothing: antialiased;
		margin: .2em 0;
		text-transform: capitalize;
	}

	a {
		display: inline-block;
		padding: .2em 1em;
		transition: background 0.2s;

		.no-touch &:hover {
			background-color: $corp-color;
		}
	}
  &.is-visible {transform: translateY(0);}

}


//Search
.search {}
.searchbox{
    position:relative;
    min-width:50px;
    width:0%;
    //height:50px;
    float:right;
    overflow:hidden;

    //Transition
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    transition: width 0.3s;
}

.searchbox-input{
    top:0;
    right:0;
    border:0;
    outline:0;
    //background:$search-color;
    width:100%;
    margin:0;
    padding:0px 55px 0px 10px;
    box-shadow: none;

    &:focus {
      background-color: $search-color;
      border: none;
    }
}

.searchbox-icon {
    width:50px;
    height:39px;
    display:block;
    position:absolute;
    top:0;
    right:0;
    padding:0;
    margin:0;
    border:0;
    outline:0;
    text-align:center;
    cursor:pointer;

    &:before {
      content: url(../img/icons/search-icon.svg);
      position: absolute;
      width: 25px;
      height: 20px;
      top: 45%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
}

.searchbox-open{width:100%;}

//********Sub Navigation**********
.sub-nav {
  background-color: $corp-color;
  padding: 1rem 0;
  text-align: center;

  .menu li {
    a {
      color: $white;
      border-right: 1px solid $white;
      padding: 0 1.5rem;
    }

    &:last-child a {border-right: none;}
  }

  .owl-carousel {
    width: 75%;
    margin: 0 auto;

    li {font-size: 80%;}
  }

  .owl-prev, .owl-next {
    position: absolute;

    &:before {
      position: absolute;
      width: 10px;
    }
  }
  .owl-prev {
    left: -10%;
    bottom: -28px;

    &:before {content: url(../img/icons/left-arrow.svg);}
  }
  .owl-next {
    right: -5%;
    bottom: -28px;

    &:before {content: url(../img/icons/right-arrow.svg);}
  }

}
