body {
  font-size: 15px;
  color: $content-font-color;

  &.overflow-hidden {overflow: hidden;} // Apply on Mobile Nav
}
a { color: $content-font-color;}


.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}
@import "fa/font-awesome";
@import "components/navbar";

@import "api/owl.carousel";
@import "api/ug-gallery";
@import "components/product";

//Main Content Styles
@import "components/content";
@import "components/footer";
@import "components/media";
