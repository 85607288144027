.hero-carousel {
  //background-image: url('../img/mobile-banner.jpg');
  position: relative;

  ul {
    color: $white;
    margin: 0;
    li {
      list-style: none;
      padding-left: 0;
			background-position: center;
		  background-repeat: no-repeat;
		  background-size: cover;
    }
  }

  @include breakpoint(medium) {
    //background-image: url('../img/banner.jpg');
  }

}

/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */

/* clearfix */
.owl-carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
/* display none until init */
.owl-carousel{
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer{
	overflow: hidden;
	position: relative;
	width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight{
	transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item{float: left;}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
	cursor: pointer;
}
.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* mouse grab icon */
.grabbing {cursor:url(grabbing.png) 8 8, move;}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
  backface-visibility: hidden;
  transform: translate3d(0,0,0);
}

.owl-controls{
	text-align: center;
  position: absolute;
  bottom: 3rem;
  width: 100%;
}

/* Styling Pagination*/
.owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
}
.owl-controls .owl-page span {
	display: block;
	background: transparent;
  width: 15px;
  height: 15px;
  margin: 5px;
  border: 2px solid $white;
  -webkit-backface-visibility: visible;
  opacity: 1;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-controls .owl-page.active span,
.owl-controls.clickable .owl-page:hover span {
	background: $white;
  opacity: 1;
}

//#blng-banner {.owl-item {min-height: 100vh;}}

.carousel-intro {
  padding: 2rem 1rem 0 ;
	position: absolute;
	top: 0;
	color:$white;

  a {
    color:$white;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 500;
    font-size: 0.8rem;
  }
  i {
    font-style: normal;
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 0.5rem;
    vertical-align: sub;
  }

}
