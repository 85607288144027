@include breakpoint(medium) {
  .blng-layout-1 {

    h1 {font-size: 2rem;}
    h2 {font-size: 1.5rem;}
    h1, h2 {text-align: left;}

    img {padding-bottom:0.8rem;}
  }
  //*************Main Navigation**********************
  nav {
    .menu {
      > li a {
        padding: 0.7rem 1.2rem;
      }
    }
  }

  //**************Carousel Banner*******************
  .carousel-intro {
    padding: 5rem 0 0 ;

    p {max-width: 620px;}
  }
  //Home Style
  //**************The Management Team***************
.the-management {
  h2 {text-align: center;}
}

  //******Home Product Section******
  .blng-home-product {

    .home-product-left {
      padding:7rem 3rem 3rem 0.9375rem;
    }
  }
  //******Home Plant Section******
  .blng-home-plant {
    padding:3rem 0;
    ul#home-plant-mini-carousel {
      //.owl-next {right: 0;}
    }
  }
  .gema-newsletter {
    padding-bottom: 4rem;

    .tabs-title-wrapper {background-color: transparent;}
    .tabs-panel {padding: 0;}
    .tabs-title { float: left;}
    .tabs li {

        a {
            padding: 0 1rem;
            margin: 1rem 0 2rem;
          }
         &:last-child a {border-right: none;}
      }

    .gema-downloadable-item img {
      max-width: 163px;
    }
  }

  //************Vision Mission***************
  .blng-house {

    .roof {
      padding: 3rem 0 1rem;
        background-size: auto;
    }
    .ceiling {margin-top:0;}

    .post-wrap {display: flex;}
    .post {flex-grow: 1;}
  }
  //************Event**************
  .event {
    img {padding-bottom: 0;}
  }
  //**********Footer***************
  footer {
    text-align: left;
    .footer-menu {
      padding: 2.5rem 0;
    }

    //*******Footer Links**********
    .footer-menu-links, .contact-address { border-bottom: none;}
    .footer-menu-links {
      .menu > li > a {padding: 0.7rem 0;}
    }

    //*****Contact Address*******
    .contact-address {
      address {
        span {
          display:block;
          margin-bottom: 1rem;
          position: relative;
          padding-left: 2rem;

            &::before, &::after {
              position: absolute;
              width: 18px;
              height: 18px;
            }

        }
        span.business-address::before {
            top: 0.3rem;
            left: 0.5rem;
            content: url(../img/icons/map-icon.svg);
        }
        span.phone::before {
            top: 0;
            left: 0.5rem;
            content: url(../img/icons/phone-icon.svg);
        }
        span.phone::after {
            top: 1.6rem;
            left: 0.5rem;
            content: url(../img/icons/fax-icon.svg);
        }
        span.email::before {
            top: 0;
            left: 0.5rem;
            content: url(../img/icons/mail-icon.svg);
        }
        span.pio::before {
            top: 0.5rem;
            left: 0.5rem;
            content: url(../img/icons/user-icon.svg);
        }
        span.linked::before {
          top: 0;
          left: 0.5rem;
          content: url(../img/icons/linkedin.svg);
        }
      }
    }

    .blng-copyright {
      p { text-align: right;}
      .tou { text-align: left;}
    }
  }
  //*************Helpers**************
  .flexview {
    display: flex;
    align-items: flex-end;
  }

}
// **************Tab and Ipad**************
@include breakpoint(medium only) {

h2 { font-size: 2rem; margin-top: 1rem;}
h4 {font-size: 1.25rem;}
p {font-size: 0.9rem;}

//Home Style
//******Carousel Banner*********
.carousel-intro {
  padding: 5rem;
}
//******Home Product Section******
  .blng-home-product {

    .home-product-left {
      padding: 0 0.625rem;
    }
  }
  //******Home Plant Section******
  .blng-home-plant {
    padding: 1rem 0;

    .home-plant-left {padding-top: 0rem;}

  }
    //******Home Links Section******
  .home-links {
    padding: 2rem 0 1rem;

    .links-item {
      border-bottom: none;
      padding: 1rem 1rem 1rem 0;
    }
  }
}
@media print {
  #map {display: block !important;}
  .accordion-content {display: block !important;}
  .accordion-title {border: 2px solid $gray;}

  .lng-chain {
    .print-lng {
      .item {
        display: block !important;
        text-align: center;
      }
      .print-title {margin-bottom: 25px;}
    }
  }
  .blng-house {

    .post-wrap {
      display:block !important;
      margin-top: 20rem;
    }
    .floor {
      display:block !important;
      margin-top: 100rem;
    }
  }

}
