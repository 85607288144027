//********Global Layout******
.blng-layout-1 {
  background-color: $mod-bgcolor;
  padding: 1rem 0 2.5rem;

    h1, h2 {color: $corp-color; text-align: center;}

    p {text-align: justify;}

    p + h2 {margin-top: 2rem;}

    p + img {
      margin: 3rem auto;
      display: block;
    }

    small {font-size: 95%; color: $top-most-nav-color;}

    .img-grid {margin: 1rem 0;}

}
//***********History**************
.history {
  .history-description {
    margin: 1rem 0;
  }
}
.blng-house {
  padding-bottom: 5rem;

  .ceiling, .floor, .post {
    background-color: $corp-color;
    color: $white;
    padding: 1rem;

    h2, h4, h5, p {
      color: $white;
      text-align: center;
    }
  }
  li {
    line-height: 1.4;
    margin-bottom: 0.3rem;
  }
  .roof {
    padding: 3rem 1rem 1rem;
    margin-top: 3rem;
    background-image: url('../img/icons/roof-background.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: $white;

    h2, p {
      color: $white;
      text-align: center;
    }
  }
  .ceiling {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .rooms {
    background-color: $gray;
    color: $corp-color;
    padding: 0.5rem;
    margin-bottom: 1rem;
    h4 {
      font-size: 1.2rem;
      text-align: center;
      font-weight: 500;

      img {
        display: block;
        margin: 0.5rem auto;
      }
    }
  }
  .floor h5, .post h5 {
    font-size: 1.2rem;
  }
  .floor {
    margin-top: 1rem;
  }

  .post {
    border-top: 1px solid;
    border-right: 1px solid;
    border-color: $white;
  }

}
//********Accordion************
.accordion {
  background-color: transparent;
}
//li:first-child .accordion-title {border-top: 1px solid $gray;}

.accordion-title {
  border-top: 1px solid $gray;

  &::before {
    top: 19%;
    margin-top: 0;
    font-size: 2rem;
    color: $gray;
    padding: .1rem .55rem .25rem;
    border: 1px solid $gray;
    border-radius: 50px;
  }

  &:hover, &:focus {color: $corp-color;}
}
is-active > .accordion-title {color: $corp-color;}

.accordion-content {

  .header-bar {
    font-size: 1.2rem;
    color: $corp-color;
    text-align: center;
    border-top: 2px solid $gray;
    border-bottom: 2px solid $gray;
    padding: .6rem 0;
  }
}

//************Milestones***********
.milestones {

li {line-height: 1.6;}
img {padding-top:0.8rem;}

.intro-milestones {
  padding: 0 1.5rem;

  @include breakpoint(large) {
      padding: 0;
  }
}

.blng-milestones {
  padding: 0;
  background-color: $white;

  @include breakpoint(medium) {
      padding: 2rem;
  }

}

.accordion {  border-bottom: 2px solid $gray;}
  .accordion-title {
    text-align: center;
    color: $white;
    background-color: $corp-color;

    &::before {color: $white;}
  }

  .accordion-content {
    background-color: $white;
    border-left: 2px solid $gray;
    border-right: 2px solid $gray;
    padding: 1rem;
    padding-bottom: 0;
  }

}
//*********Timeline***********
.milestones-tl-container {
	margin: 0 auto;

	&::after { /* clearfix */
		content: '';
		display: table;
		clear: both;
	}
}

.milestones-timeline {
	position: relative;

	&::before {
		/* this is the vertical line */
		content: '';
		position: absolute;
		top: 0;
		left: 5px;
		height: 100%;
		width: 2px;
		background:$corp-color;
	}

	@include breakpoint(medium) {

		&::before {
			left: 50%;
			margin-left: -2px;
		}
	}
}

.tl-block {
	position: relative;
	margin: 2em 0;


  &::after { /* clearfix */
		content: '';
		display: table;
		clear: both;
	}

	&:first-child {margin-top: -15px;}
  &:last-child {padding-bottom: 2rem;}
}

.tl-dot {
	position: absolute;
	top: 55px;
	left: -4px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
  background: $corp-color;

	img {
		display: block;
		width: 24px;
		height: 24px;
		position: relative;
		left: 50%;
		top: 50%;
		margin-left: -12px;
		margin-top: -12px;
	}

	&.tl-dot-icon {background: $corp-color;}

	@include breakpoint(medium) {
		left: 50%;
		margin-left: -11px;

		/* Force Hardware Acceleration in WebKit */
		-webkit-transform: translateZ(0);
		-webkit-backface-visibility: hidden;

		.cssanimations &.is-hidden {
			visibility: hidden;
		}

		.cssanimations &.bounce-in {
			visibility: visible;
			animation: cd-bounce-1 0.6s;
		}
	}
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.tl-content {
	position: relative;
  top: 35px;
	margin-left: 40px;
	background: $mod-bgcolor;
	padding: 1em;

  &::after { /* clearfix */
		content: '';
		display: table;
		clear: both;
	}

	ul {
    margin-left: 1rem;
    margin-top: .8rem;
  }

	.tl-year {display: inline-block;}

	p {
		margin: 0;
		line-height: 1.6;
	}

	.tl-year {
    color: $corp-color;
    font-weight: 600;
		padding: .2em 0;
	}

	&::before {
		content: '';
		position: absolute;
		top: 16px;
		right: 100%;
		height: 0;
		width: 0;
		border: 15px solid transparent;
		border-right: 15px solid $mod-bgcolor;
	}

	@include breakpoint(medium) {
		margin-left: 0;
		padding: 1em;
		width: 42%;
    top: 28px;

		&::before {
			top: 24px;
			left: 100%;
			border-color: transparent;
			border-left-color: $mod-bgcolor;
		}

    ul {margin-top: 0;}

		.tl-year {
			position: absolute;
			width: 100%;
			left: 107%;
			top: 9px;
			font-size:16px;
      font-weight: 500;
      padding: .8em 0;
		}

		.tl-block:nth-child(even) & {
			float: right;
      top: 25px;
      margin-bottom: 1rem;

			&::before {
				top: 22px;
				left: auto;
				right: 100%;
				border-color: transparent;
				border-right-color: $mod-bgcolor;
			}

			.tl-year {
				left: auto;
        top: 13px;
				right: 107%;
				text-align: right;
			}
		}

		.cssanimations &.is-hidden {visibility: hidden;}

		.cssanimations &.bounce-in {
			visibility: visible;
			animation: cd-bounce-2-inverse 0.6s;
		}
	}

  @include breakpoint(medium only) {
    .tl-year {left: 125%;}

    .tl-block:nth-child(even) & {
      .tl-year {right: 125%;}
    }
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0);
  }
}

//***********Event***************
.event, .gallery {
  .menu li {
    a {
      color: $corp-color;
      font-weight: 500;
      border-right: 1px solid $gray;
      padding: 0 1rem;
      margin: 0 0 1rem; //1rem 0 2rem
    }
    &:last-child a {border-right: none;}
  }
  .album-list-2 {
    padding: .78rem;
    background-color: $white;
    margin-bottom: 2rem;

    ul.owl-carousel {
      width: 75%;
      margin: 0 auto;

      li {list-style-type: none;}
      a {color: $corp-color; font-weight: 500;}
      .owl-wrapper-outer {text-align: center;}
    }

    .owl-prev, .owl-next {
      position: absolute;

      &:before {
        position: absolute;
        width: 10px;
      }
    }
    .owl-prev {
      left: -12%;
      bottom: -27px;

      &:before {content: url(../img/icons/left-arrow-green.svg);}
    }
    .owl-next {
      right: -6%;
      bottom: -27px;
      &:before {content: url(../img/icons/right-arrow-green.svg);}
    }
  }

}
.album-grid-cover {
    padding-top: 0.3rem;
    padding-bottom:0.3rem;

    a, .card-divider {transition: .5s ease-in-out 0s;}

    a:hover .card-divider {
      background-color:$corp-color;
      color: $white;
    }

    .album-title p {text-align: center;}

    img {padding-bottom: 0;width: 100%;}

    .menu li {
      a {
        color: $corp-color;
        border-right: 1px solid #333;
      }
      &:last-child a {border-right: none;}
    }
}
//**********Global LNG News************
 .global-news {
   img {
     padding-bottom: 0;
     width: 100%;
   }
   .card {
     display: table;
   }
   .news-header img {
     width: auto;
   }
   .card-divider, .card-section {
     padding: 1rem 1.5rem;
   }
   .newsfeed img {
     padding-bottom: 4px;
   }
   .news-title a {
     font-size: 1.25rem;
     line-height: 1.2;
     padding-top: 0.8rem;
     color: $corp-color;
   }
   .news-date, .source {
     font-size: 80%;
     color: $top-most-nav-color;
   }
   .card-section p {
     text-align: left;
     line-height: 1.6;
   }
   .news-read-more {
     text-align: center;
     color: $corp-color;
     font-size: 80%;
     text-transform: uppercase;
     display:block;
     letter-spacing: 3px;

     i {
       font-style: normal;
       font-size: 1.3rem;
       font-weight: bold;
       margin-right: 0.4rem;
       vertical-align: sub;
     }

   }
 }
//************GEMA Newsletter***************
.gema-newsletter {
  h2 {text-align: left;}

  .tabs {background: transparent;}
  .tabs li {
      a {
          color: $corp-color;
          font-weight: 500;
          font-size: 15px;
          border-right: 1px solid $gray;
          padding: 1rem;

          //margin: 1rem 0 2rem;
        }

       &:last-child a {border-right: none;}
    }
    .tabs-title-wrapper {
      background-color: $white;
      margin-right: -0.625rem;
      margin-left: -0.625rem;
    }
    .tabs-title { float: none; text-align: center;}
    .owl-carousel {
      width: 75%;
      margin: 0 auto;

      li {font-size: 80%;}
    }

    .owl-prev, .owl-next {
      position: absolute;

      &:before {
        position: absolute;
        width: 10px;
      }
    }
    .owl-prev {
      left: -12%;
      bottom: -16px;

      &:before {content: url(../img/icons/left-arrow-green.svg);}
    }
    .owl-next {
      right: -8%;
      bottom: -16px;
      &:before {content: url(../img/icons/right-arrow-green.svg);}
    }


  .gema-downloadable-item {
    margin: 1.5rem 0;
  }
  .gema-date {font-size: 80%;}
  .gema-title {
    margin-bottom: 0;
    //font-weight: 500;
  }
  .media-object-section {vertical-align: middle;}

}
//************Resources**************
.resources {
  background-color: $white;
  padding-bottom: 0;

   h2 {font-weight: 500;}
   h4, h5 {
     color: $corp-color;
     font-weight: 500;
     padding-top: 1rem;
   }
   h5 {font-size: 1.1rem;}
   img {padding-bottom: 0;}

  .media-object-section {vertical-align: bottom;}

  .resources-doc {padding: 2rem 0 3rem;}

  .document-pdf-downloadable img { max-width: 163px; border: 1px solid #ccc}

  .resources-video {
    background-color: $mod-bgcolor;
    padding: 3rem 0;

    .column {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
    }
    .video-thumbnail {
      position: relative;
      cursor: pointer;
      .play-icon {
        background: rgba(0,0,0,1);

        //position: relative;
        &:before {
          content: url('../img/icons/play-btn.svg');
          position: absolute;
          bottom:20px;
          left:20px;
          width: 50px;
          border-radius: 50%;
          background: rgba(0,0,0,.5);
          padding: 12px 18px;
          transition: width 1s ease-in-out 0s;
        }

      }
      //&:hover .play-icon::before {width: 100%;}
    }

  }
}
//************LOGO Page********************
.logo {
  background-color: $white;

    img {padding-bottom: 0;}

  .logo-dload:first-child .card-section {
    background-color: $corp-color;
  }
  .card-divider {
    background-color: $mod-bgcolor;
    padding: 1.2rem 1rem;
  }
  .card-section {padding: 3rem;}

  .logo-dload-btn {text-align: center;}

  .logo-guideline {margin: 2rem 0;}

  .media-object-section {
    vertical-align: bottom;
    img { max-width: 163px;}
  }
  div + p { margin-bottom: 0; font-weight: 500;}
  li { line-height: 1.3;}
  h5 {color: $corp-color;}

  .download-btn {
    color: $corp-color;
    font-weight: 500;
    margin-left: 20px;
    position: relative;

    &:before {
      content: url('../img/icons/dload-icon.svg');
      position: absolute;
      top: 1px;
      left: -25px;
      width: 20px;
    }
  }
}

//**************Contact Us******************
.contact {
  background-color: $white;

  #map {
    width: 100%;
    height: 560px;
    background-color: $gray;
    margin: 1rem 0;
  }

  .contact-form {
     label + p {text-align: right;}

     a.button {
       background-color: $corp-color;
       padding: 0.5rem 1rem;
     }

  }

  .local-business-address {
    address {
        font-style: normal;
        padding-top: 1rem;
      span {
        display:block;
        margin-bottom: 1rem;
        position: relative;
        padding-left: 2rem;

          &::before, &::after {
            position: absolute;
            width: 18px;
            height: 18px;
          }

      }
      span.business-address::before {
          top: 0.3rem;
          left: 0.5rem;
          content: url(../img/icons/map-blue-icon.svg);
      }
      span.phone::before {
          top: 0;
          left: 0.5rem;
          content: url(../img/icons/phone-blue-icon.svg);
      }
      span.phone::after {
          top: 1.6rem;
          left: 0.5rem;
          content: url(../img/icons/fax-blue-icon.svg);
      }
      span.email::before {
          top: 0;
          left: 0.5rem;
          content: url(../img/icons/mail-blue-icon.svg);
      }
      span.pio::before {
          top: 0.5rem;
          left: 0.5rem;
          content: url(../img/icons/user-blue-icon.svg);
      }
    }
  }
}

//**************Products******************
.products {
  padding-bottom: 0;

  img {display: block; margin: 0 auto .5rem}
  p + img {margin: 1.5rem auto;}

  .lng-chain {
    background-color: $white;
    padding: 2rem 0;
    margin-top:1rem;

    .tabs-title {
      text-align: center;
      float: none;

      > a {
        font-size: 1rem;
        color:$gray;
        transition: .2s ease-in-out 0s;
        font-weight: 500;
      }

      img {display: block; margin: 0 auto .5rem}
    }

    .is-active > a {color: $corp-color;}

    .owl-item:nth-child(3) .tabs-title {padding-top:1.8em;}

    #lng-chain-mobile {
      list-style: none;
      margin-left: 0;
      text-align: center;

      .owl-item:nth-child(3) {padding-top:1.8em;}
      span {
          font-weight: 500;
          color: $corp-color;
      }
      img {margin: 1rem auto 2rem;}
    }

    .owl-prev, .owl-next {
      position: absolute;

      &:before {
        position: absolute;
        width: 15px;
        bottom: 50%;
      }
    }
    .owl-prev {
      left: 0;
      bottom: 550px;

      &:before {content: url(../img/icons/left-arrow-green.svg);}
    }
    .owl-next {
      right: 3%;
      bottom: 550px;
      &:before {content: url(../img/icons/right-arrow-green.svg);}
    }

  }
  .lng-process {
    padding: 2rem 0;

    img {margin: 1rem auto; display: block;}
  }
  .certification {
    background-color: $white;
    padding: 2rem 0;

    img {margin: 1rem auto; display: block;}
    p {text-align: center;}

  }
}
//***********The Plant*************
.the-plant, .work-life-in-brunei-lng {
  img {
    margin: 0 0 1rem;
  }
}
//*************The Management*************
.the-management {

  h2 {text-align: center;}

  #lng-leadership, #extented-leadership-team {
   width:100%;
   height: 300px;
   display: relative;
   margin-top: 1.5rem;

    img {
      display: none;
      cursor: pointer;
    }
  }
  .leadership-team {
    margin: 2rem 0 3rem;
  }
  .leader-desc {
    position: relative;
    height: 110px;

    [id$="leader"], [id$="extended"] {
      position: absolute;
      top: 0;
      width: 100%;
			opacity: 0;
      transition: .3s ease-in-out 0s;

			&.show {opacity: 1;}
		}
  }
  .profile-info {
    p {line-height: 1.3;}

    span {
      text-align: center;
      display: block;
      font-size: 1.2rem;
    }
    .name {}
    .positions {font-weight: bold;}
    .position-desc { font-style: italic; font-weight: 100;}

  }
}
//***********Helpers**************
.adobe-icon {
  position: relative;
  margin-left: 35px;

  &:before {
    position: absolute;
    top: 0;
    left: -28px;
    content: url('../img/icons/adobe-icon.svg');
    width: 21px;
    height: 21px;
  }
}
.adobe-link {
  display: inline-block;
  clear: both;
  margin: 2rem 0 4rem;

  small {font-size: 70%; float: left;}
  a { float: left; margin-right: .5rem;}
}

/************Reveal Modal***************/
.reveal {
  text-align: center;
  padding: 3rem 4rem;

  h1 {
    color:  $corp-color;
    font-size: 2rem;
  }
  p {
    font-size: 90%;
  }
}
.video-res {
  padding: 0rem;
  border: 0;

  .responsive-embed {
    margin-bottom: 0;
  }

}
.vid-title {
  color:  $corp-color;
  font-weight: 500;
}
//************Search*****************
.sisea-result {
  padding: 1rem;
  margin: 0.5rem 0;
  border-bottom: 1px solid $corp-color;
  h3 {font-size: 1.3rem;}
  a { color: $corp-color;}
  &:last-child { border-bottom: none;}
}
//*********Customers************
.customers {
  //img { padding-bottom: 1rem;}

  .customer-map {
    position: relative;

    img {
      display: block;
      margin: 1rem auto;
    }
  }
  .japan, .korea {
      border: 3px solid #ffff00;
      -webkit-border-radius: 30px;
      height: 22px;
      width: 22px;
      position: absolute;
      cursor: pointer;
      -webkit-animation: pulsate 1s ease-out;
      -webkit-animation-iteration-count: infinite;
      opacity: 0.0
  }

  @-webkit-keyframes pulsate {
      0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
      50% {opacity: 1.0;}
      100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
  }
  .japan-info, .korea-info {
    position: absolute;
    padding:.5rem;
    background: $white;


    span {display: block;
          font-size: .8rem;
      &:first-child {
        text-transform: uppercase;

        font-weight: 500;
      }
    }
  }
  .japan {
    left:76.7%;
    top:16.8%;
  }
  .korea {
    left:57.7%;
    top:16.8%;
  }

  .japan-info {
    width: 98px;
    height: 100px;
    top: 26%;
    left: 71%;
  }
  .korea-info {
    width: 83px;
    height: 66px;
    top: 20%;
    left: 30%;
  }

    @include breakpoint(medium) {
        .japan-info, .korea-info {
        position: absolute;
        padding:1rem;
        background: $white;


        span {display: block;
          &:first-child {
            text-transform: uppercase;
            font-size: 1.3rem;
            font-weight: 500;
          }
        }
      }
      .japan {
        left:69.5%;
        top:155px;
      }
      .japan-info {
        width: 130px;
        height: 140px;
        top: 20%;
        left: 72%;
        opacity: 0;
        transition: .3s ease-in-out 0s;
      }
      .japan:hover + .japan-info {opacity: 1;}
      .korea {
        left:56.7%;
        top:155px;
      }
      .korea-info {
        width: 130px;
        height: 95px;
        top: 20%;
        left: 45%;
        opacity: 0;
        transition: .3s ease-in-out 0s;
      }
      .korea:hover + .korea-info {opacity: 1;}
    }
}

.social-wrap {
  color: #888;
  text-align: right;
  span {
    margin: 0 3px;
    cursor: pointer;
  }
}

.social {
  position: relative;
  display: none;
  .share-btn {
    background-color: $white;
    border-radius: 5px;
    position: absolute;
    right: -6px;
    top: 6px;
    list-style: none;
    margin: 0;
    padding: .5rem;

    li {text-align: center;}
    i {margin:0;color: #888;}

  }

}
//************Breadcrumbs****************
.breadcrumbs {
  display: flex;
  li {
    font-weight: bold;
  }
}
