
//Home Product Section
.blng-home-product {
  background-color: $corp-color;

  .home-product-left {
    color: $white;
    padding:1rem;
  }
ul#home-product {
    padding-left: 0;
    margin: 0;

    li {list-style: none;}
    a {
      color:$white;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-weight: 500;
      font-size: 0.8rem;
    }
    i {
      font-style: normal;
      font-size: 1.5rem;
      font-weight: bold;
      margin-right: 0.5rem;
      vertical-align: sub;
    }
  .owl-controls {
    position: static;
  }
}

}
//Home Plant
.blng-home-plant {
  background-color: $mod-bgcolor;
  padding:1rem 0 0;
  a {
    color:$content-font-color;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 500;
    font-size: 0.8rem;

  }
  i {
    font-style: normal;
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 0.5rem;
    vertical-align: sub;
  }
  .home-plant-left {
    margin-bottom: 1rem;

    h2 {color: $corp-color;}

  }
  .plant-carousel {
    margin-top: 3rem;
  }
}
ul#home-plant-mini-carousel {
  margin-top: 1rem;
  margin-left: 0;
  list-style-type: none;

  p {
    text-align: center;
    margin-bottom: 0;
  }

  .owl-item img {
    display: block;
    width: auto;
    max-height: 103px;
    margin: 0 auto 1rem;
  }

  .caption {font-weight: bold;}


  .owl-prev, .owl-next {
    position: absolute;

    &:before {
      position: absolute;
      width: 20px;
    }
  }
  .owl-prev {
    top: -210px;
    left: 3%;
    &:before {content: url(../img/icons/left-arrow.svg);}
  }
  .owl-next {
    top: -210px;
    right: 9%;
    &:before {content: url(../img/icons/right-arrow.svg);}
  }
  @include breakpoint(medium) {
    .desc {
      //opacity:0;
      transition: opacity .5s ease-in-out 0s;
    }
    .item:hover .desc{opacity: 1;}

    .owl-prev {
      top: -210px;
      left: -3%;
    }
    .owl-next {
      top: -210px;
      right: -2%;
    }

  }

}
//Home Links
.home-links {
  padding: 2rem 0 3rem;

  .links-item {
    border-bottom: 1px solid $mod-bgcolor;
    padding: 1.4rem 0;

    &:last-child {border-bottom:none;}

    h2, h3 { color: $corp-color; margin-bottom: 0;}
    h2 {font-size: 1rem;}
    h3 {font-size: 1.2rem; font-weight: 500;}
    p {font-size: 0.8rem;}
    a {
      text-transform: uppercase;
      letter-spacing: 5px;
      font-weight: 500;
      font-size: 0.8rem;
    }
    i {
      font-style: normal;
      font-size: 1.5rem;
      font-weight: bold;
      margin-right: 0.5rem;
      vertical-align: sub;
    }
  }
}
