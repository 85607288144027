footer {
  background-color: $corp-color;
  font-size: 85%;

  text-align: center;
//Footer menu
  .footer-links {
      padding:1rem 0;

      a, h5 { color: $white}
      h5 {font-weight: 500;}
  }
  //*******Footer Links**********
  .footer-menu-links, .contact-address {
    padding: 1rem;
    border-bottom: 1px solid $white;

    // Menu Item
    .menu > li > a {padding: 0.5rem 0;}
  }
  //*****Contact Address*******
  .contact-address {
    address {
      font-style: normal;
      color: $white;

      span.linked {
        display: block;
      }
    }
  }

  //******Newsletter*******
  .newsletter {
    padding: 1rem;
    .newsletter-input {
      border-color: $white;
      box-shadow: none;
      background-color: $white;
    }
    .btn-newsletter {
      padding: 0.7rem 2rem;
      background-color: transparent;
      border: 1px solid $white;
      color: $white;
    }
  }
  //*******Copyright*********
  .blng-copyright {
    border-top: 1px solid $black;
    padding:1rem 0 2rem;

    text-align: center;

    p { margin: 0;}
  }
    .tou {
      margin: 0;

      li {
        list-style: none;
        display: inline;

        a {padding: 0 0.5rem;}
      }
    }
}
